/* IE */
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQStFwTeUPOQ.woff) format('woff');
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSo9wTeUPOQ.woff) format('woff');
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSlF3TeUPOQ.woff) format('woff');
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM69GCVc0feE.woff) format('woff');
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCVc0feE.woff) format('woff');
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6_GDlc0feE.woff) format('woff');
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6__Dlc0feE.woff) format('woff');
}
  
/* Chrome */
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNwCbHhL4.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNwSbHhL4.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNzybH.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNwCbHhL4.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNwSbHhL4.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNzybH.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNwCbHhL4.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNwSbHhL4.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Rosario';
    font-style: italic;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfuz0WDhWW_fOEoY2FbNzybH.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2Fz9zT7D.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2F39zT7D.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2FP9zQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2Fz9zT7D.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2F39zT7D.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2FP9zQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2Fz9zT7D.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2F39zT7D.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/rosario/v18/xfux0WDhWW_fOEoY2FP9zQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}